import styles from "../styles/NavBar.module.css";
import {Link} from "react-router-dom";


const MuffinFooter = () => {
    return (
        <nav className={styles.navBar}>
            <Link to="/">
                {/*<h1 className={styles.siteHeading}>Muffin Hockey</h1>*/}
                <img className={styles.muffinLogo} src={`${process.env.PUBLIC_URL}/muffinman.png`}
                     alt={'muffin hockey logo'}/>
            </Link>
            {/*<Link to="/players">*/}
            {/*     <button className={styles.randomButton}>Random Player</button>*/}
            {/*</Link>*/}
            <p>Play-by-play data pulled from the NHL.</p>
            <a href="https://twitter.com/MuffinHockey" target="_blank" rel="noopener noreferrer">
                Created using @MuffinHockey xG model.
            </a>
            <p>If you have recommendations for improvement or want to work together, email me - hellopeterknowles@gmail.com.</p>
        </nav>
    );
}
export {MuffinFooter};