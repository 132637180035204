import React, {Component, useEffect, useState} from "react";
import {BrowserRouter, Link, Route, Routes} from "react-router-dom";
import TeamDetailPage from "./TeamPlayersPage";
import profileStyles from '../styles/PlayerProfile.module.css'
import styles from '../styles/TeamSelectionPage.module.css'
import { useParams } from 'react-router-dom'
import {getColor} from "../helpers/colors";

function getFlagSrc(nationality) {
    const flagMap = {
        USA: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Flag_of_the_United_States_%28DoS_ECA_Color_Standard%29.svg/510px-Flag_of_the_United_States_%28DoS_ECA_Color_Standard%29.svg.png',
        CAN: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Flag_of_Canada.svg/1600px-Flag_of_Canada.svg.png?20190318175205',
        SWE: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Flag_of_Sweden.svg/1600px-Flag_of_Sweden.svg.png?20240601033150',
        FIN: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Flag_of_Finland.svg/1600px-Flag_of_Finland.svg.png?20230220191416',
        CZE: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Flag_of_the_Czech_Republic.svg/1599px-Flag_of_the_Czech_Republic.svg.png?20110920204324',
        RUS: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Flag_of_Russia.svg/1599px-Flag_of_Russia.svg.png?20120812011549',
        BGR: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Flag_of_Russia.svg/1599px-Flag_of_Russia.svg.png?20120812011549',
        DEN: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/Flag_of_Denmark.svg/1024px-Flag_of_Denmark.svg.png?20230220190747',
        SUI: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Flag_of_Switzerland.svg/1024px-Flag_of_Switzerland.svg.png?20191016012602',
        CHE: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Flag_of_Switzerland.svg/1024px-Flag_of_Switzerland.svg.png?20191016012602',
        SVK: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Flag_of_Slovakia.svg/1599px-Flag_of_Slovakia.svg.png?20221214030619',
        UZB: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Flag_of_Uzbekistan.svg/1600px-Flag_of_Uzbekistan.svg.png?20230421060058',
        BEL: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/85/Flag_of_Belarus.svg/1600px-Flag_of_Belarus.svg.png?20230207071054',
        AUS: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Flag_of_Australia.svg/1600px-Flag_of_Australia.svg.png?20211007161007',
        AUT: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Flag_of_Austria.svg/1599px-Flag_of_Austria.svg.png?20220412141234',
        GBR: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg/1600px-Flag_of_the_United_Kingdom_%283-5%29.svg.png?20230715230526',
        DEU: 'https://upload.wikimedia.org/wikipedia/en/thumb/b/ba/Flag_of_Germany.svg/510px-Flag_of_Germany.svg.png',
        LAT: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Flag_of_Latvia.svg/1600px-Flag_of_Latvia.svg.png?20240509004811'
    };

    return flagMap[nationality] || 'Flag not found';
}


function getPositionString(nhl_position) {
    const positionMap = {
        L: 'LW',
        R: 'RW',
        C: 'C',
        D: 'D',
        G: 'G'
    };

    return positionMap[nhl_position] || '?';
}


function getAgeString(birth_date) {
    const birthDate = new Date(birth_date);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    // Adjust age if the current date is before the birth date this year
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
    }

    return age;
}


function getHeightString(height_inches) {
    const feet = Math.floor(height_inches / 12);
    const inches = height_inches % 12;
    return `${feet}'${inches}"`;
}


function PlayerProfile({ player, playerCard }) {
    // console.log(playerCard)

    useEffect(() => {
        // function getColor(value) {
        //     const minValue = 0;
        //     const maxValue = 100;
        //     const midValue = 50; // Midpoint value for the gradient
        //
        //     const minColor = '#ea4336'; // Red
        //     const midColor = '#eeeeee'; // Gray
        //     const maxColor = '#34a853'; // Green
        //
        //     let color;
        //     if (value <= midValue) {
        //         // Interpolate between red and gray
        //         const ratio = (value - minValue) / (midValue - minValue);
        //         color = interpolateColor(minColor, midColor, ratio);
        //     } else {
        //         // Interpolate between gray and green
        //         const ratio = (value - midValue) / (maxValue - midValue);
        //         color = interpolateColor(midColor, maxColor, ratio);
        //     }
        //     return color;
        // }
        // function interpolateColor(color1, color2, ratio) {
        //     const [r1, g1, b1] = hexToRgb(color1);
        //     const [r2, g2, b2] = hexToRgb(color2);
        //
        //     const r = Math.round(r1 + (r2 - r1) * ratio);
        //     const g = Math.round(g1 + (g2 - g1) * ratio);
        //     const b = Math.round(b1 + (b2 - b1) * ratio);
        //
        //     return `rgb(${r}, ${g}, ${b})`;
        // }
        // function hexToRgb(hex) {
        //     let r = 0, g = 0, b = 0;
        //     if (hex.length === 7) {
        //         r = parseInt(hex.slice(1, 3), 16);
        //         g = parseInt(hex.slice(3, 5), 16);
        //         b = parseInt(hex.slice(5, 7), 16);
        //     }
        //     return [r, g, b];
        // }
        // const overallCircle = document.getElementById('overall');
        // const value = parseInt(overallCircle.getAttribute('data-value'), 10);
        // const circle = overallCircle.querySelector(`.${profileStyles.overallCircle}`);
        // if (circle) {
        //     circle.style.borderColor = getColor(value);
        // }

        const cells = document.querySelectorAll(`.${profileStyles.analytic}`);
        cells.forEach(cell => {
            const value = parseInt(cell.getAttribute('data-value'), 10);
            const circle = cell.querySelector(`.${profileStyles.analyticCircle}`);
            if (circle) {
                circle.style.borderColor = getColor(value);
            }
        });

    //     const loadingContainers = document.getElementsByName('loadingBar');
    //     loadingContainers.forEach(loadingContainer => {
    //         const loadingBar = loadingContainer.querySelector(`.${profileStyles.loadingBar}`);
    //         const loadingText = loadingBar.querySelector(`.${profileStyles.loadingText}`);
    //         const loadingValue = parseInt(loadingContainer.getAttribute('data-value'), 10);
    //
    //         // Ensure the value is between 0 and 100
    //         const percentage = Math.max(0, Math.min(100, loadingValue));
    //
    //         // Set the width of the loading bar based on the data-value attribute
    //         loadingBar.style.width = `${percentage}%`;
    //         loadingBar.style.backgroundColor = getColor(loadingValue);
    //         // Set the text inside the loading bar
    //         loadingText.textContent = `${percentage}`;
    //     });
    //
    //     const overallContainer = document.getElementById('overallBar')
    //     const overallBar = overallContainer.querySelector(`.${profileStyles.overallBar}`);
    //     const overallText = overallBar.querySelector(`.${profileStyles.overallText}`);
    //     const overallValue = parseInt(overallContainer.getAttribute('data-value'), 10);
    //     const percentage = Math.max(0, Math.min(100, overallValue));
    //     overallBar.style.width = `${percentage}%`;
    //     overallBar.style.backgroundColor = getColor(overallValue);
    //     // Set the text inside the loading bar
    //     overallText.textContent = `${percentage}`;
    //
    // }, [player]); // Empty dependency array ensures this runs only once after initial render
    const loadingContainers = document.getElementsByName('loadingBar');
        loadingContainers.forEach(loadingContainer => {
            const loadingBar = loadingContainer.querySelector(`.${profileStyles.loadingBar}`);
            const loadingText = loadingBar.querySelector(`.${profileStyles.loadingText}`);
            const loadingValue = parseInt(loadingContainer.getAttribute('data-value'), 10);

            if (isNaN(loadingValue)) {
                // Handle the case where loadingValue is not a number
                loadingBar.style.width = `0%`;
                loadingBar.style.backgroundColor = getColor(0); // Or set to a default color
                loadingText.textContent = 'N/A';
            } else {
                // Ensure the value is between 0 and 100
                const percentage = Math.max(0, Math.min(100, loadingValue));
                loadingBar.style.width = `${percentage}%`;
                loadingBar.style.backgroundColor = getColor(loadingValue);
                loadingText.textContent = `${percentage}`;
            }
        });

        const overallContainer = document.getElementById('overallBar');
        const overallBar = overallContainer.querySelector(`.${profileStyles.overallBar}`);
        const overallText = overallBar.querySelector(`.${profileStyles.overallText}`);
        // const overallValue = parseInt(overallContainer.getAttribute('data-value'), 10);
        const overallValue = Math.round(parseFloat(overallContainer.getAttribute('data-value')));

        if (isNaN(overallValue)) {
            overallBar.style.width = `0%`;
            overallBar.style.backgroundColor = getColor(0); // Or default color
            overallText.textContent = 'N/A';
        } else {
            const percentage = Math.max(0, Math.min(100, overallValue));
            overallBar.style.width = `${percentage}%`;
            overallBar.style.backgroundColor = getColor(overallValue);
            overallText.textContent = `${percentage}`;
            // console.log(percentage)
        }
    }, [player]);
        // Reusable component for a single stat row
    const AnalyticRow = ({ label, value }) => (
      <tr>
        <td>{label}</td>
        <td className={profileStyles.analytic} data-value={value}>
          <div className={profileStyles.analyticCircle}>
            <span>{value !== null && value !== undefined ? value : 'n/a'}</span>
          </div>
        </td>
      </tr>
    );

    // Reusable component for an analytics category
    const AnalyticCategory = ({ title, loadingValue, stats }) => (
      <div className={profileStyles.analyticCategory}>
        <h1>{title}</h1>
        <div
          className={profileStyles.loadingContainer}
          data-value={loadingValue !== null && loadingValue !== undefined ? loadingValue : 'n/a'}
          name="loadingBar"
        >
          <div className={profileStyles.loadingBar}>
            <span className={profileStyles.loadingText}></span>
          </div>
        </div>
        <table>
          <tbody>
            {stats.map((stat, index) => (
              <AnalyticRow key={index} label={stat.label} value={stat.value} />
            ))}
          </tbody>
        </table>
      </div>
    );

    let statsData;
    if (player.position !== 'G') {
     statsData = {
         evenStrength: {
             title: 'Even Strength',
             loadingValue: playerCard.even_strength_rating, // Replace with dynamic value if available
             stats: [
                 {label: 'Offense', value: playerCard.ev_offense},
                 {label: 'Defense', value: playerCard.ev_defense},
             ],
         },
         specialTeams: {
             title: 'Special Teams',
             loadingValue: playerCard.special_teams_rating,
             stats: [
                 {label: 'Powerplay', value: playerCard.powerplay_rating},
                 {label: 'Penalty Kill', value: playerCard.pk_defense},
             ],
         },
         scoring: {
             title: 'Scoring',
             loadingValue: playerCard.scoring_rating,
             stats: [
                 {label: 'EV xGoals', value: playerCard.ev_expected_goals},
                 {label: 'EV Goals', value: playerCard.ev_goals},
                 {label: 'PP xGoals', value: playerCard.pp_expected_goals},
                 {label: 'PP Goals', value: playerCard.pp_goals},
                 {label: 'Shots on Goal', value: playerCard.shots_on_goal},
             ],
         },
         passing: {
             title: 'Passing',
             loadingValue: playerCard.passing_rating,
             stats: [
                 {label: 'EV Primary Assists', value: playerCard.ev_primaries},
                 {label: 'EV Secondary Assists', value: playerCard.ev_secondaries},
                 {label: 'PP Primary Assists', value: playerCard.pp_primaries},
                 {label: 'PP Secondary Assists', value: playerCard.pp_secondaries},
             ],
         },
         details: {
             title: 'Details',
             loadingValue: playerCard.details_rating,
             stats: [
                 {label: 'Penalties Taken', value: playerCard.penalties_taken},
                 {label: 'Penalties Drawn', value: playerCard.penalties_drawn},
                 {label: 'Turnovers', value: playerCard.turnovers},
                 {label: 'Takeaways', value: playerCard.takeaways},
                 {label: 'Blocked Shots', value: playerCard.blocked_shots},
                 {label: 'Hits', value: playerCard.hits},
                 {label: 'Faceoffs', value: playerCard.faceoff_percentage},
             ],
         },
         deployment: {
             title: 'Deployment',
             loadingValue: playerCard.deployment_rating,
             stats: [
                 {label: 'EV TOI', value: playerCard.ev_toi},
                 {label: 'PP TOI', value: playerCard.pp_toi},
                 {label: 'SH TOI', value: playerCard.sh_toi},
                 {label: 'O-Zone Starts', value: playerCard.ev_oz_starts},
                 {label: 'D-Zone Starts', value: playerCard.ev_dz_starts},
                 {label: 'QOT', value: playerCard.qot},
                 {label: 'QOC', value: playerCard.qoc},
            ],
        },
    }
} else {
    statsData = {
        puckStopping: {
            title: 'Puck Stopping',
            loadingValue: playerCard.puck_stopping, // Replace with dynamic value if available
            stats: [
                {label: 'Low Danger Shots', value: playerCard.low_danger_sv_percentage},
                {label: 'Medium Danger Shots', value: playerCard.medium_danger_sv_percentage},
                {label: 'High Danger Shots', value: playerCard.high_danger_sv_percentage},
                {label: 'Meaningful Shots', value: playerCard.meaningful_sv_percentage},
                {label: 'Goals Saved Above Expected', value: playerCard.gsax},
            ],
        },
        gamePerformance: {
            title: 'Game Performances',
            loadingValue: playerCard.game_performances_rating, // Replace with dynamic value if available
            stats: [
                {label: 'Quality Starts', value: playerCard.quality_starts},
                {label: 'Games Stolen', value: playerCard.games_stolen},
                {label: 'Role Difficulty', value: playerCard.xg_against},
            ],
        },
    }
}

    // Render the component
    return (
        <div className={profileStyles.page}>
            <div className={profileStyles.tableOutline}>
                <div>
                    <div className={profileStyles.profileHeader}>
                        <img
                            className={profileStyles.mug}
                            src={`https://assets.nhle.com/mugs/nhl/20242025/${player.current_team}/${player.id}.png`}
                            alt={`${player.first_name} ${player.last_name}`}
                        />
                        <div className={profileStyles.profileInfo}>
                            <div className={profileStyles.nameBarContainer}>
                                <h1 className={profileStyles.nameBar}>
                                    {player.first_name} {player.last_name}
                                </h1>
                            </div>
                            <div className={profileStyles.bioBackground}>
                                <div className={profileStyles.bioContainer}>
                                    <div className={profileStyles.bioBox}>
                                        <div className={profileStyles.bioItem}>
                                            <img
                                                className={profileStyles.teamLogo}
                                                src={`https://assets.nhle.com/logos/nhl/svg/${player.current_team}_light.svg`}
                                                alt={`${player.current_team} logo`}
                                            />
                                        </div>
                                        <div className={profileStyles.bioItem}>
                                            {getPositionString(player.position)}
                                        </div>
                                        <div className={profileStyles.bioItem}>
                                            {getAgeString(player.birth_date)}
                                        </div>
                                        <div className={profileStyles.bioItem}>
                                            {getHeightString(player.height_inches)}
                                        </div>
                                        <div className={profileStyles.bioItem}>
                                            {`${player.weight_lbs} lbs`}
                                        </div>
                                        <div className={profileStyles.bioItem}>
                                            <img
                                                className={profileStyles.flag}
                                                src={getFlagSrc(player.nationality)}
                                                alt={`${player.nationality} flag`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={profileStyles.overallContainer}
                                data-value={playerCard.overall_rating}
                                id="overallBar"
                            >
                                <div className={profileStyles.overallBar}>
                                    <span className={profileStyles.overallText}></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className={profileStyles.divider}
                    style={{ backgroundColor: playerCard.primary_color}} />
                    <div className={profileStyles.gridContainer}>
                        {/* Map over the statsData to render each category */}
                        {Object.values(statsData).map((category, index) => (
                            <AnalyticCategory
                                key={index}
                                title={category.title}
                                loadingValue={category.loadingValue}
                                stats={category.stats}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <p className={profileStyles.explainer}>
                All numbers represent per-toi percentile ranks for position among qualifying players.
            </p>
            <p className={profileStyles.explainer}> Percentiles are created using the most recent 100 games for players / 75 for goalies that the player has participated in, with
                more weight applied to more recent games. </p>
            <p className={profileStyles.explainer}> Overall scores are not an exact science, but a mixed
                weighting of stats, with more emphasis placed on offensive stats for forwards and defensive
                stats for defensemen. Overall ratings do not always imply that one player is 'better' than
                another player. Player cards are best used as a tool to see the specific strengths and weakness of a player.</p>
            <p className={profileStyles.explainer}>All data originates from NHL play-by-play data. Twitter @MuffinHockey</p>
            <footer>
                <section className={profileStyles.glossary}>
                    <h2>Glossary</h2>
                    <dl>
                        {/*<dt>Note</dt>*/}
                        {/*<dd>All numbers represent per-toi percentile ranks for position among qualifying players. Percentiles are created using the most recent 150 games the player has participated in, with more weight applied to more recent games. Overall scores are not an exact science, but a mixed weighting of stats, with more emphasis placed on offensive stats for forwards and defensive stats for defensemen. Overall ratings do not always imply that one player is 'better' than another player. The nature of percentile ranks is that McDavid can only earn 99s in offensive categories, even though he might deserve a rating of above that.</dd>*/}

                        <dt>QOC</dt>
                        <dd>Quality of competition, weighted by how much time is spent head-to-head at 5v5.</dd>

                        <dt>QOT</dt>
                        <dd>Quality of teammates that the player plays with, weighted by how much time is spent together
                            at 5v5.
                        </dd>

                        <dt>QOC</dt>
                        <dd>Quality of competition, weighted by how much time is spent head-to-head at 5v5.</dd>

                        <dt>QOT</dt>
                        <dd>Quality of teammates that the player plays with, weighted by how much time is spent together
                            at 5v5.
                        </dd>

                        <dt>Quality Starts (Goalie)</dt>
                        <dd>A quality start is a game where the goalie starts and has a save percentage at or above 90%
                            when the game ends.
                        </dd>

                        <dt>Games Stolen (Goalie)</dt>
                        <dd>A game is stolen by a goalie when their team wins and the margin of victory is less than or
                            equal to the amount of goals they saved above expected.
                        </dd>

                        <dt>Meaningful Shots (Goalie)</dt>
                        <dd>A shot is considered 'meaningful' if the score is either tied or within one goal. It is meant to capture the performance of the goalie in the highest stake game situations.
                        </dd>

                    </dl>
                </section>
            </footer>
        </div>
    );
}


function PlayerPage() {
    const {playerId} = useParams(); // Destructure playerId from params
    const [player, setPlayer] = useState(null); // Initialize as null for no data
    const [playerCard, setPlayerCard] = useState(null); // Initialize as null for no data
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        async function getData() {
            setLoading(true);
            try {
                const cardResp = await fetch(`https://muffinhockey.com/api/players/id/${playerId}/player-card`);
                const cardInfo = await cardResp.json();
                setPlayerCard(cardInfo);
            } catch (error) {
                console.error(error);
                setLoading(true);
            }

            try {
                const response = await fetch(`https://muffinhockey.com/api/players/id/${playerId}`);
                const playerInfo = await response.json();
                setPlayer(playerInfo);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }

        getData();
    }, [playerId]); // Add playerId as a dependency

    if (loading) return (
        <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );

    return player ? <PlayerProfile player={player} playerCard={playerCard}/> : <div>Player not found</div>;
}


export default PlayerPage


